
    
    .BarAndPieBox {
        float: left;
        width: 610px;
        border-radius: 8px;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
        height: 285px;
        background-color: #fff;
        
        margin-right: 20px;

        tr, td { border: none !important; }

        &:last-child { float: right;}
        .loadingContent {
            width: 100%;
            min-height: 190px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .HorizontalBarGraph .valueRow {
            padding: 0;
        }

        .viewAll {
            margin-left: 20px;
        }

     
    

    .barAndPieWapper {
        padding-left: 20px;
    }

    .chartHeader {
        display: flex;
        flex-direction: row;
        padding: 8px 10px 10px 20px;
        margin: 0 15px 0 0;
        border-bottom: 1px solid #F0ECE9;
        .chartHeaderTitle {
            .ButtonWithOptions {
                min-width: 126px;
                margin-left: 10px;
                float: right;
                .SCButton { text-align: left;}
            }
        }
        .spacer {
            flex: 1;

        }
    
    .chartKey {
        .chartKeyItem {
            font-size: 14px;
            color: #A8A4A2;
            display: inline;
            margin-left: 15px;
            .chartKeyBox {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 2px;
                margin-right:  5px;
                margin-bottom: 1px;
            }
        }
    }
}

    }