.SimulationView {
    textarea {
        min-height: 200px;
    }
}
.SimulationItem {
    h1 {
        margin: 0;
    }

    padding: 10px; 
    border:1px solid #ccc;
    background-color: #fff;
    margin-bottom: 15px;

    &.isInvalid {
        border-color: #ff0000;
    }
}