@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Source+Sans+Pro:wght@400;600&display=swap');
@import "node_modules/bulma/sass/utilities/_all.sass"; // This must be loaded first of the bulma includes
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F5F1EF !important;
}
html {  background-color: #F5F1EF !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.panel {
  background-color: #fff;
}
