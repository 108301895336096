
%font-1 {
  font-family: 'Nunito', sans-serif;
}

%font-2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.App {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  position: fixed;
  top:0; left:0; bottom:0; right:0;
}

  .content {
    padding: 90px 40px;
    z-index: 1;
  }

