.boxRow { 
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 190px;
    height: 117px;
    background-color: #fff;
    color: #A8A4A2;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
    .boxNumber {
      color:#413B38; 
      font-size: 30px;
    }
    margin:0 20px 0  0;
  }