.NavBar {
    position: fixed;
    top:0; left:0; right:0;
    background-color: #fff;
    height: 70px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 40px;
    z-index: 2;
    .leftNav, .centerNav, .rightNav {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex: 1;

    }
    .leftNav {  justify-content: flex-start;  }
    .centerNav {  margin: 0 20px;  }
    .rightNav {  justify-content: flex-end;  }

  }