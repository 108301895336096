@charset "utf-8";



$red:    #E92200;
$red-light: #FFEDEA;
$orange: #F67902;
$yellow: #FFA900;
$yellow-light: #FFF5E2;

$teal:   #00CABE;
$teal-light: #E1F6F5;

$white: #FFFFFF;

$dark-brown:  #413B38;
$light-brown:  #5E5450;


$primary-dark-grey:  #413B38;
$primary-light-grey: #5E5450;

$grey-0: #A8A4A2;
$grey-1: #C3BCB9;
$grey-2: #DAD3D0;
$grey-3: #E9E3DF;
$grey-4: #F0ECE9;
$grey-5: #F5F1EF;
$bright: #FDF6F3;
$poppy:  #FFF5E2;


// Update Bulma's global variables
$text:$primary-dark-grey;
$text-light: $grey-0;

$button-color:$white;
$button-background-color:$dark-brown;
$button-text-hover-color:$white;
$button-hover-color:$white;
$button-text-hover-background-color:$orange;
$button-active-color:$white;
$button-focus-color:$white;
$button-focus-border-color: transparent;
$button-border-width:0;
$button-focus-box-shadow-size: 0 0 0 0;

$button-disabled-background-color:$dark-brown;


$primary: $orange;
$link: $orange;
$info: $yellow;
$success: $teal;
$warning: $red;
$danger: $red;
$dark: $dark-brown;
$text: $dark-brown;


// Update some of Bulma's component variables
$body-background-color: #F5F1EF;

$input-border-color: $primary;
$input-shadow: none;

//Testing 123

input[type=radio] {
    margin-right: 5px;
  }
.clearfix::after { 
    content: " ";
    display: block; 
    height: 0; 
    clear: both;
 }
 
%noselect {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
 .noselect {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
 }